$main-color: #aa0000;
$second-color: #ffe143;

// @mixin color-with-opacity($color, $opacity) {
//   background-color: rgba($color, $opacity);
// }

.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-loader {
  width: 100vw;
  height: 100vh;
  background-color: $main-color;

  img {
    width: 50vw;
    animation: rotate infinite 10s linear;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.position-fixed {
  position: fixed;
  z-index: 9;
}

.btn-red {
  background-color: $main-color;
  color: #ffffff;
  border: solid 1px $main-color;

  a {
    color: #ffffff;
    text-decoration: none;
  }
}

.btn-red:hover {
  background-color: $second-color;
  color: #000000;
  border: solid 1px $second-color;

  a {
    color: #000000;
    text-decoration: none;
  }
}

.btn-red-outline {
  background-color: transparent;
  color: $main-color;
  border: solid 1px $main-color;

  a {
    color: $main-color;
    text-decoration: none;
  }
}

.btn-red-outline:hover {
  background-color: transparent;
  color: #000000;
  border: solid 1px $second-color;

  a {
    color: #000000;
    text-decoration: none;
  }
}

.menu {
  background-color: $main-color;
  font-size: 16px;
  font-weight: bold;

  .brand-logo {
    width: 100px;
    // height: 50px;
  }

  .nav-link {
    color: #ffffff;
  }
  .nav-link:hover,
  .nav-link.show {
    color: $second-color;
  }

  .profile-menu {
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.landing-page {
  background-image: url("../public/images/hero.jpg");
  background-size: cover;
  height: 100vh;
  width: 100vw;
  // z-index: 1;
  .bg {
    position: absolute;
  }

  .card {
    background-color: $main-color;
    color: #ffffff;
    width: 30rem;
    height: 28rem;
    margin-left: 100px;
  }
}

.login-page {
  background-color: $main-color;
  height: 100vh;
  width: 100vw;

  .card {
    color: #ffffff;
    width: 30rem;
    height: 30rem;
  }
}

.branch-page iframe {
  width: 100%;
  height: 80vh;
}

.pdf-viewer {
  position: relative;
  overflow: auto;
  width: 100%;
  height: 80vh;
  border: solid 1px black;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* pointer-events: none; */
}

.org-chart {
  width: 100%;
  overflow: auto;

  .chart-node {
    // padding: 1em 0.5em;
    // border: solid 2px $main-color;
    width: 250px;
    // height: 150px;
    margin: auto;
    border-radius: 10px;

    span {
      width: 100%;
      text-align: left;
    }
  }

  // .active.chart-node:hover {
  //   background-color: rgba($main-color, 0.2);
  // }

  .icon {
    width: 50px;
  }
}

.profile-modal {
  // width: 500px;

  .profile-picture {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
